(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("filestack-js"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["prop-types", "filestack-js", "react"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("prop-types"), require("filestack-js"), require("react")) : factory(root["prop-types"], root["filestack-js"], root["react"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 